.carousel_img {
    width: 100%;
    height: auto;
    margin-top: 50px
}

.button_create_accont {
    border: none;
    padding: 10px;
    color: #fff;
    background-color: #72B953;
    margin-bottom: 0px;
}

.robo_container_home {
    background-image: url("../../../images/Arquivos_Landing_Page/barra_verde.png");
    background-size: cover;
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.desciption_robo_home span {
    width: 50%;
}

.description_home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    word-wrap: normal
}

.robot_img {
    width: 30%;
    display: flex;
    justify-content: start;
    align-items: start;
}

.robot_img img {
    width: 50%;
    margin-top: -70px;
    margin-left: 50px;
}

.footer_home {
    width: 100vw;
    height: auto;
    background-color: #72B953;
}

.container_kid_home {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (min-width: 768px) {
    .menu_home {
        right: -2050px;
    }

    .container_links_home
    {
        display: flex;
    }

    .menuactive {
        right: -2050px;
    }

    .container_kid_home {
        width: 40%;
    }
}

.smartphone {
    width: 100%;
    transform: translateX(10%);
}

@media screen and (max-width: 768px) {
    .container_links_home a {
        display: none;
    }

    .container_links_home
    {
        display: none;
    }

    .container_menu_home {
        display: flex;
    }

    .container_kid_home {
        width: 100%;
    }

}

.robo_img_home {
    position: absolute;
    right: 10rem;
    top: 73.2rem;
}

.img_robo {
    width: 70%;
    height: auto;
}

@media screen and (max-width: 1200px) {
    .robot_img img {
        width: 55%;
    }
}


@media screen and (max-width: 1100px) {
    .robot_img img {
        width: 80%;
    }
}

@media screen and (max-width: 800px) {
    .robot_img img {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .robot_img img {
        display: none;
    }

    .center_robo_text_home {
        width: 100%;
        text-align: center;
    }

    .robo_container_home {
        display: block;
    }
}

.beneficios,
.container_register{
    display: flex; justify-content: center; align-items: center; 
}
.beneficios
{
    justify-content: space-around;
}

.container_register
{
    padding: 10px;
}


@media screen and (max-width: 567px) {
    .beneficios
    {
        flex-direction: row;
    }
    .container_register
    {
        flex-direction: column;
    }
}