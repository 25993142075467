.container_login {
    display: flex;
    background-color: #fff;
    width: 100%;
    height: auto;
    overflow-x: hidden;
}

.login_img {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.login_img img {
    width: 100%;
    height: 100%;
}

.login_logo {
    position: absolute;
    width: 150px;
    top: 10rem;
    right: 43.5%;
}

.form-login {
    width: 50%;
    display: flex;
    padding: 3rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-login img {
    width: 100px;
}

.align-item-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.align-item-center img 
{
    border-radius: 100%;
}

.align-item-end {
    display: flex;
    justify-content: end;
    align-items: center;
}

.forgetpassword {
    color: rgb(120, 120, 120);
    text-decoration: none;
}

.forgetpassword {
    width: 100%;
    text-align: end;
    padding-right: 10px;
}

.footer-form-login a:hover,
.forgetpassword:hover {
    opacity: 0.7;
    transition: 0.5s;
    cursor: pointer;
}

.btn-login {
    width: 100%;
    padding: 15px;
    color: #fff;
    border-radius: 10px;
    border: none;
    background: linear-gradient(to right, #4E8937, #70B751);
}

.footer-form-login {
    margin-top: 2rem;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}

.footer-form-login a {
    color: #6DBE45;
    text-decoration: none;
}

@media screen and (max-width: 1280px) {
    .login_img {
        width: 60%;
    }

    .form-login {
        width: 40%;
    }

}

@media screen and (max-width: 1000px) {
    .login_img {
        width: 65%;
    }

    .form-login {
        width: 35%;
    }

}

.logoweb {
    display: block;
}

.logomobile {
    display: none;
}

@media screen and (max-width: 900px) {
    .container_login {
        flex-direction: column;
    }

    .login_img {
        width: 120%;
    }

    .form-login {
        width: 100%;
    }

    .logoweb {
        display: none;
    }

    .logomobile {
        display: block;
    }

}