body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header_home {
  width: 100vw;
  height: 60px;
  box-shadow: -3px 2px 10px rgba(0, 0, 0, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  position: fixed;
  z-index: 100;
}

.header_home div img {
  width: 200px;
}

.container_menu_active_home,
.container_menu_home,
.container_img_home,
.container_links_home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_menu_active_home {
  justify-content: start;
  padding: 10px;
}

.container_menu_home {
  justify-content: end;
  display: none;
}

.container_links_home a {
  text-decoration: none;
  margin-right: 20px;
  color: black;
  font-weight: bold;
}

.container_links_home a:nth-child(4) {
  padding: 10px;
  color: #fff;
  background-color: #72B953;
}

.container_links_home a:hover {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.5s;
}

.menu_home {
  width: 300px;
  background-color: #fff;
  position: fixed;
  right: -500px;
  box-shadow: -1px 0px 10px rgba(0, 0, 0, 0.7);
  transition: 0.5s;
  z-index: 900;
  height: 100%;
}

.container_links_menu_home_mobile .dropdown button
{
  text-decoration: none;
  padding: 0;
  margin-bottom: 20px;
  color: black;
  font-weight: bold;
  font-size: 1.5rem;
}

.container_links_menu_home_mobile,
.container_links_menu_home {
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 45%;
  padding: 15px;
  font-size: 1.7rem;
}

.container_links_menu_home_mobile a,
.container_links_menu_home a {
  margin-top: 2rem;
  text-decoration: none;
  margin-bottom: 20px;
  color: black;
  font-weight: bold;
}

.container_links_menu_home a:nth-child(4) {
  padding: 10px;
  color: #fff;
  background-color: #72B953;
  margin-bottom: 0px;
}

.menuactive {
  right: 0;
  top: 0;
}