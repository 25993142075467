.loader {
    width: 100vw;
    height: 100%; /* O loader ocupará toda a altura do corpo ou contêiner */
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader div {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.loader div img {
    width: 250px;
    height: 250px;
    animation: is-rotating 1s infinite;
}

@keyframes is-rotating {
    to {
        transform: rotate(1turn);
    }
}

.hidden {
    display: none;
}