.input_create_account {
    border: none;
    background: #e3e3e3;
    width: 100%;
    padding: 5px;
}

.background_create_account {
    background-position: 10%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
    width: 100%;
    word-break: break-all;
    background-image: url('../../../images/interna_criarconta.jpg');
    position: relative;
}

.background_create_account h3 {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-190%);
    margin: 0;
    background-color: #6EBF3F;
    padding: 2px;
    width: 300px;
    text-align: center;
    font-weight: bold;
    color: #fff;
}

@media screen and (max-width: 1100px) {
    .background_create_account h3
    {
        transform: translateX(-180%);
    }
}
@media screen and (max-width: 1000px) {
    .background_create_account h3
    {
        transform: translateX(-150%);
    }
}
@media screen and (max-width: 880px) {
    .background_create_account h3
    {
        transform: translateX(-125%);
    }
}
@media screen and (max-width: 730px){
    .background_create_account h3
    {
        transform: translateX(-100%);
    }
}
@media screen and (max-width: 540px){
    .background_create_account h3
    {
        transform: translateX(-80%);
    }
}
@media screen and (max-width: 400px){
    .background_create_account h3
    {
        transform: translateX(-50%);
    }
}
