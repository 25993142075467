.icon {
    vertical-align: middle;
    line-height: 1px;
    font-size: 20px;
}

.icon_header {
    vertical-align: middle;
    line-height: 1px;
    font-size: 26px;
}

.icon,
.icon_header {
    margin-right: 5px;
}

.close_icon {
    color: red;
    margin-left: 30px;
    margin-top: 10px;
    cursor: pointer;
}

.grid-container {
    display: grid;
    grid-template-columns: 260px 1fr 1fr 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
        'sidebar header header header'
        'sidebar main main main';
    height: 100vh;
}

/* Header  */
.header {
    grid-area: header;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
}

.menu-icon {
    display: none;
}

/* End Header  */

/* Sidebar  */
#sidebar {
    grid-area: sidebar;
    height: 100%;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

.sidebar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 0px 30px;
    margin-bottom: 30px;
}

.sidebar-title>span {
    display: none;
}

.sidebar-brand {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
}

.sidebar-list {
    padding: 0;
    list-style-type: none;
}

.sidebar-list-item {
    padding: 20px 20px 20px 20px;
    font-size: 18px;
}

.sidebar-list-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
}

.sidebar-list-item>a {
    text-decoration: none;
    color: #9e9ea4;
}

.sidebar-responsive {
    display: inline !important;
    position: absolute;
    /*
      we want the z-index of the sidebar higher so that
      the charts are not showing over the sidebar 
      on small screens
    */
    z-index: 12 !important;
}

/* End Sidebar  */


/* Main  */
.main-container {
    grid-area: main;
    color: rgba(255, 255, 255, 0.95);
}

.main-title {
    display: flex;
    justify-content: space-between;
}

.main-cards {
    display: flex;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: 15px 0;
    justify-content: center;
    align-items: center;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 5px;
    width: 300px;
}

.card-inner h3,
.card h1 {
    color: #fff;
}

.card:first-child {
    background-color: #2962ff;
}

.card:nth-child(2) {
    background-color: #ff6d00;
}

.card:nth-child(3) {
    background-color: #d50000;
}

.card-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-inner>.card_icon {
    font-size: 25px;
}

.charts {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    height: 30rem;
    margin-top: 11rem;
}

.filter {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.container-table {
    width: 45%;
    height: 100%;
    overflow: auto;
    margin-left: 10;
}

/* End Main  */


/* Medium <= 992px */

@media screen and (max-width: 992px) {
    .grid-container {
        grid-template-columns: 1fr;
        grid-template-rows: 0.2fr 3fr;
        grid-template-areas:
            'header'
            'main';
    }

    #sidebar {
        display: none;
    }

    .menu-icon {
        display: inline;
    }

    .sidebar-title>span {
        display: inline;
    }
}

/* Small <= 768px */

@media screen and (max-width: 768px) {
    .main-cards {
        grid-template-columns: 1fr;
        gap: 10px;
        margin-bottom: 0;
    }

    .charts {
        grid-template-columns: 1fr;
        margin-top: 30px;
    }

    .main-cards,
    .container-charts {
        flex-direction: column;
    }

    .container-table
    {
        width: 100%;
    }

    .charts
    {
        width: 100%;
    }
    .recharts-responsive-container
    {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.container-charts {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.ribbon {
    width: 100%;
    height: 5.5rem;
    top: -0.5rem;
    background-color: #5c5be5;
    position: absolute;
    left: -1rem;
    -webkit-box-shadow: 0px 15px 11px -6px #7a7a7d;
    box-shadow: 0px 15px 11px -6px #7a7a7d;
}

/* Extra Small <= 576px */

@media screen and (max-width: 576px) {
    .hedaer-left {
        display: none;
    }
}

.header_table_ranking {
    background: #6CBD45;
    width: 100%;
    padding: 1rem;
}

.table_ranking {
    width: 100%;
}

.table_ranking thead {
    width: 100%;
    background: #6CBD45;
}

.count_rollback,
.position_ranking {
    text-align: center;
    width: 20%;
}

.playlist_ranking {
    text-align: center;
    width: 80%;
}

.table_ranking tbody tr:nth-last-of-type(2n) {
    background: #f2f2f2;
    color: #000;
    font-size: 25px;
}

.table_ranking tbody tr:nth-last-of-type(1n) {
    color: #000;
    font-size: 25px;
    padding: 10px;
}


.table_ranking tbody tr {
    text-align: center;
}